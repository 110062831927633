<template>
  <div>
    <div class="header-pc">
      <div class="header-pc-l">
        <a class="logo" href="/">
          <img src="@/assets/images/logo.svg" />
        </a>
        <div class="menu-list">
          <HeaderItem v-for="(item, i) in menu" :item="item" :key="i" @showCreator="showCreator = true" />
        </div>
      </div>
      <div class="header-pc-r">
        <HeaderSearch />
        <a href="/referral" class="referral">
          <svg viewBox="0 0 256 256">
            <path fill="currentColor"
              d="M197.58 129.06L146 110l-19-51.62a15.92 15.92 0 0 0-29.88 0L78 110l-51.62 19a15.92 15.92 0 0 0 0 29.88L78 178l19 51.62a15.92 15.92 0 0 0 29.88 0L146 178l51.62-19a15.92 15.92 0 0 0 0-29.88ZM137 164.22a8 8 0 0 0-4.74 4.74L112 223.85L91.78 169a8 8 0 0 0-4.78-4.78L32.15 144L87 123.78a8 8 0 0 0 4.78-4.78L112 64.15L132.22 119a8 8 0 0 0 4.74 4.74L191.85 144ZM144 40a8 8 0 0 1 8-8h16V16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 0 16h-16v16a8 8 0 0 1-16 0V48h-16a8 8 0 0 1-8-8m104 48a8 8 0 0 1-8 8h-8v8a8 8 0 0 1-16 0v-8h-8a8 8 0 0 1 0-16h8v-8a8 8 0 0 1 16 0v8h8a8 8 0 0 1 8 8" />
          </svg>
          <span>Refer</span>
        </a>
        <div class="wallet g-clickable" @click="showPortfolio = true" v-if="currentAccount">
          <svg viewBox="0 0 1024 1024">
            <path
              d="M704.682667 128C851.797333 128 938.666667 212.693333 938.666667 357.632h-180.522667v1.450667c-83.797333 0-151.722667 66.218667-151.722667 147.925333s67.925333 147.925333 151.722667 147.925333H938.666667v13.312C938.666667 811.306667 851.797333 896 704.682667 896H319.317333C172.202667 896 85.333333 811.306667 85.333333 668.245333V355.754667C85.333333 212.693333 172.202667 128 319.317333 128z m202.112 293.205333c17.621333 0 31.872 13.909333 31.872 31.061334v107.989333a31.829333 31.829333 0 0 1-31.872 31.061333H761.6c-42.410667 0.554667-79.530667-27.733333-89.130667-68.053333a84.565333 84.565333 0 0 1 18.474667-70.485333 89.258667 89.258667 0 0 1 67.242667-31.573334z m-128.170667 49.92h-14.037333a32.682667 32.682667 0 0 0-23.04 9.088 31.061333 31.061333 0 0 0-9.557334 22.357334c0 17.621333 14.506667 32 32.597334 32.170666h14.037333a32.213333 32.213333 0 0 0 32.597333-31.786666 32.213333 32.213333 0 0 0-32.597333-31.829334z m-250.325333-177.066666H287.488a32.213333 32.213333 0 0 0-32.64 31.402666c0 17.621333 14.549333 32 32.64 32.170667h240.810667a32.213333 32.213333 0 0 0 32.64-31.829333 32.213333 32.213333 0 0 0-32.64-31.786667z"
              fill="currentColor"></path>
          </svg>
          <span class="g-loader" v-if="loading && !currentSolBalance"></span>
          <span v-else>{{ currentSolBalance | nFormatter(4) }}</span>
          <img src="@/assets/token/icon-sol.svg" />
        </div>
        <div class="login g-clickable" @click="connectTry" v-else>
          <svg viewBox="0 0 1024 1024">
            <path
              d="M298.666667 298.666667a213.333333 213.333333 0 1 0 213.333333-213.333334 213.333333 213.333333 0 0 0-213.333333 213.333334zM128 831.573333z m0 0z m0 0z m768 2.56z m0 0z m-768 0z m578.56-275.2h-17.493333a50.346667 50.346667 0 0 0-20.906667 5.12s-14.08 8.106667-26.88 13.653334a331.946667 331.946667 0 0 1-128 25.6 321.706667 321.706667 0 0 1-128-25.6c-12.8-5.546667-27.306667-13.653333-27.306667-13.653334a42.666667 42.666667 0 0 0-20.906666-5.12h-18.346667a219.306667 219.306667 0 0 0-165.12 136.533334 362.666667 362.666667 0 0 0-25.6 134.4 42.666667 42.666667 0 0 0 21.333333 32.426666S268.8 938.666667 512 938.666667s362.666667-76.373333 362.666667-76.373334a42.666667 42.666667 0 0 0 21.333333-32.853333 346.88 346.88 0 0 0-24.746667-132.266667 218.88 218.88 0 0 0-164.693333-139.946666zM896 829.866667z m0 0z"
              fill="currentColor"></path>
          </svg>
          <span>Login</span>
        </div>
      </div>
    </div>
    <SoarConnect @close="showConnect = false" v-if="showConnect" />
    <SoarCreator @close="showCreator = false" v-if="showCreator" />
    <!-- v-show 保证tokenList会持续更新，可以全局公用获取tokenList相关逻辑 -->
    <Portfolio @close="showPortfolio = false" @deposit="showDeposit = true" @withdraw="showWithdrawal = true" v-show="showPortfolio" :showPortfolio="showPortfolio" />
    <SoarDeposit @close="showDeposit = false" v-if="showDeposit" />
    <SoarWithdrawal @close="showWithdrawal = false" v-if="showWithdrawal" />
  </div>
</template>

<script>
import SoarCreator from "@/components/SoarCreator";
import SoarConnect from "@/components/SoarConnect";
import Portfolio from "@/components/Portfolio";
import SoarDeposit from "@/components/SoarDeposit";
import SoarWithdrawal from "@/components/SoarWithdrawal";
import HeaderItem from "@/components/HeaderItem";
import HeaderSearch from "@/components/HeaderSearch";
import { MENU } from "@/utils/config";
import { getChainByCode } from "@/utils/chain";
import { getBalance, disConnect } from "@/init";
import { tryConnect } from "@/init";
import { WalletList } from "@/utils/connect";
import { getAppWalletCode } from "@/utils/helper";

export default {
  components: {
    SoarCreator,
    SoarConnect,
    Portfolio,
    HeaderItem,
    HeaderSearch,
    SoarDeposit,
    SoarWithdrawal,
  },
  computed: {
    chain () {
      if (!this.chainCode) return;
      return getChainByCode(this.chainCode);
    },
    page () {
      return window.location.pathname.replace(/^.*?\//gi, "") || "dashboard";
    },
  },
  data () {
    return {
      menu: MENU,
      showPortfolio: false,
      showCreator: false,
      showConnect: false,
      showDeposit: false,
      showWithdrawal: false,
      menuList: [
        {
          code: "copy",
          name: "Copy Address",
        },
        {
          code: "portfolio",
          name: "Portfolio",
        },
        {
          code: "change",
          name: "Change Wallet",
        },
        {
          code: "disconnect",
          name: "Disconnect",
        },
      ],
      loading: true,
    };
  },
  watch: {
    currentAccount () {
      this.load();
    },
  },
  mounted () {
    this.load();
    this.$eventBus.$on("showConnect", (data) => {
	  if (this.currentAccount) return;
      disConnect();
      this.connectTry()
    });
  },
	methods: {
	async connectTry() {
		const walletCode=getAppWalletCode()
		if (walletCode) {
			await this.connectWallet(walletCode)
		} else {
			this.showConnect=true;
		}
	},
	async connectWallet(walletCode) {
		let wallet=WalletList.find(item => {
			return item.code==walletCode
		})
		const {code, connect}=wallet;
		await tryConnect(code, connect);
	},
    async load () {
      if (!this.currentAccount) return;

      this.loading = true;
      await getBalance(
		this.currentAccount,
        "So11111111111111111111111111111111111111112"
      );
      this.loading = false;
    },
    async menuClick (item) {
      this.isShow = false;
      const { code } = item;
      switch (code) {
        case "copy":
          this.copy(this.currentAccount);
          break;
        case "change":
          this.showConnect = true;
          break;
        case "portfolio":
          window.location.href = "/portfolio";
          break;
        case "disconnect":
          disConnect();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-pc {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  padding: 10px 12px;

  .header-pc-l,
  .header-pc-r {
    display: flex;
    align-items: center;
  }

  .menu-list {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 750px) {
      display: none;
    }
  }

  .logo {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;

    img {
      height: 36px;
    }

    @media (max-width: 750px) {
      flex: 1;
    }
  }

  .referral {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: rgba(251, 191, 36, 0.05);
    padding: 0 15px;
    border-radius: 10px;
    text-decoration: none;
    margin-right: 10px;

    svg {
      height: 16px;
      color: var(--color-yellow);
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      color: var(--color-yellow);
    }
  }

  .wallet {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 0 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;

    svg {
      width: 16px;
      color: var(--color-des);
    }

    span {
      font-size: 14px;
      color: var(--color);
      margin: 0 5px;
    }

    .g-loader {
      width: 12px;
      height: 12px;
    }

    img {
      width: 20px;
    }
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
    background: var(--color-yellow);

    svg {
      width: 20px;
      color: #000;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }
  }
}
</style>